// btn
.btn {
	padding-left: 15px;
	padding-right: 15px;
	font-size: 15px;
	font-weight: 700;
	font-family: $font-family-secondary;

	// btn-primary
	&.btn-primary {
		&:hover {
			color: $gray;
			background-color: $secondary;
			border-color: $secondary;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}

	// btn-tertiary
	&.btn-tertiary {
		color: $white;
		background-color: $tertiary;
		border-color: $tertiary;

		&:hover {
			color: $white;
			background-color: darken($tertiary, 15%);
			border-color: darken($tertiary, 15%);
		}
	}

	// btn-outline-tertiary
	&.btn-outline-tertiary {
		color: $tertiary;
		background-color: transparent;
		border-color: $tertiary;

		&:hover {
			color: $white;
			background-color: darken($tertiary, 15%);
			border-color: darken($tertiary, 15%);
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-tertiary;

	&::after {
		content: "\f063";
		margin-left: 5px;
		font-weight: 900;
		font-family: $font-awesome;
	}
}
