// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	box-shadow: 0 0 8px rgba($black, 0.15);
	transition: 0.5s;

	.container-fluid {
		max-width: none;

		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 32px;
			height: 32px;
			margin: 0 10px 0 -5px;
			padding: 0;
			border: none;
			color: $blue;
			font-size: 24px;
			transition: color 0.5s;

			i {
				line-height: 32px;
				font-weight: 400;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 200px;
		margin: 10px 0;
		transition: width 0.5s;

		@include media-breakpoint-down(lg) {
			margin-right: auto;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin: 15px 0;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $gray;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $green;
						}

						&.dropdown-toggle {
							&::after {
								content: "\f107";
								font-family: $font-awesome;
								border: none;
							}
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;
							font-weight: 400;

							.nav-link {
								color: $gray;

								&:hover {
									color: $green;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			justify-content: center;

			.navbar-nav {
				>.nav-item {
					>.nav-link {
						padding-left: 15px !important;
						padding-right: 15px !important;
						color: $gray;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $green;
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}
				}

				.nav-item {
					.dropdown-toggle {
						&::after {
							content: '\f107';
							font-family: $font-awesome;
							border: none;
							margin-left: 5px;
							vertical-align: middle;
						}
					}

					.dropdown-menu {
						margin-top: 0;
						padding: 10px;
						border: none;
						left: 0 !important;
						transform: none !important;
						box-shadow: $shadow;

						&::before,
						&::after {
							display: none;
						}

						.nav-item {
							font-size: 16px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $gray;

								&:hover {
									color: $green;
								}
							}

							&.active {
								.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		a {
			@extend .btn;
			@extend .btn-tertiary;

			@media screen and (max-width: 1399px) {
				width: 32px;
				height: 32px;
				padding: 0 !important;

				i {
					font-size: 20px;
					line-height: 30px;
				}

				.list-item-title {
					display: none;
				}
			}

			@media screen and (min-width: 1400px) {

				padding-left: 25px;
				padding-right: 25px;

				i {
					display: none;
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 10px;

		@include media-breakpoint-up(xl) {
			margin-left: 15px;
		}

		&.mobile {
			display: block;

			@include media-breakpoint-up(md) {
				display: none;
			}

			.dropdown-toggle {
				border: none !important;
				border-radius: 0 !important;
				box-shadow: none !important;
				color: $gray;
				font-size: 16px;
				line-height: 32px;
				font-family: $font-family-secondary;
				text-transform: uppercase;

				&::after {
					margin-left: 0;
				}
			}

			.dropdown-menu {
				font-size: 15px;
				font-family: $font-family-secondary;

				.dropdown-item {
					padding: 0.25rem 0.5rem !important;
				}
			}
		}

		&.desktop {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}
		}

	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
