// light
.bg-light {

	// custom-list
	.custom-list,
	.custom-list-extra {
		li {
			color: $gray;

			&::before {
				color: $blue;
			}

			a {
				color: $gray !important;

				&:hover {
					color: $blue !important;
				}
			}
		}
	}

}

// dark
.bg-dark {

	// custom-list
	.custom-list,
	.custom-list-extra {
		li {
			color: $white;

			&::before {
				color: $blue;
			}

			a {
				color: $white !important;

				&:hover {
					color: $blue !important;
				}
			}
		}
	}

}
