// body
& {
	font-size: 17px;
}

// container
.container-fluid {
	max-width: 1360px;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	box-shadow: 10px;

	.card-image {
		.card-image-caption {
			padding: 0;

			.card-image-label {
				padding: 5px 15px;
				color: $white;
				font-weight: 700;
			}
		}
	}
}

// link
.link {
	font-weight: 700;
	text-decoration: none;

	&:hover {
		color: $green;
	}
}
