// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// usp-section
// =========================================================================
.usp-section {
	padding: 25px 0;

	ul {
		@extend .custom-list-extra;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		li {
			margin: 2.5px 10px;

			@include media-breakpoint-up(xl) {
				margin-left: 45px;
				margin-right: 45px;
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 3vh 0 -3vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// content-block-section
// =========================================================================
.content-block-section {
	padding: 0 !important;

	&.bg-light {
		.column {
			&.one {
				&::before {
					content: "";
					z-index: 100;
					position: absolute;

					@include media-breakpoint-down(sm) {
						top: calc(100% - 15px);
						left: 0;
						right: 0;
					}

					@include media-breakpoint-up(md) {
						top: 50%;
						left: 100%;
						transform: translate(-50%, -50%);
					}

					display: block;
					width: 184px;
					height: 29px;
					margin: 0 auto;
					background: url('/images/wave-double-green.svg') no-repeat;
					background-size: cover;
				}
			}
		}
	}

	&.bg-dark {
		.column {
			&.one {
				&::before {
					content: "";
					z-index: 100;
					position: absolute;

					@include media-breakpoint-down(sm) {
						top: -15px;
						left: 0;
						right: 0;
					}

					@include media-breakpoint-up(md) {
						top: 50%;
						left: 100%;
						transform: translate(-50%, -50%);
					}

					display: block;
					width: 184px;
					height: 29px;
					margin: 0 auto;
					background: url('/images/wave-double.svg') no-repeat;
					background-size: cover;
				}
			}

			&.two {
				@include media-breakpoint-down(sm) {
					order: -1;
				}
			}
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			min-height: 600px;
			margin-bottom: 0;

			.column {
				margin-bottom: 0;
				justify-content: center;

				&.one,
				&.two {
					padding-top: 6vh;
					padding-bottom: 6vh;
				}

				&.one {
					@include media-breakpoint-up(md) {
						padding-right: 125px !important;
					}

					@include media-breakpoint-up(xl) {
						padding-left: 45px !important;
					}

					@media (min-width: 1360px) {
						padding-left: 125px !important;
					}

					&.with-image,
					&.with-bg-image {
						padding-top: 0;
						padding-bottom: 0;
					}

					&.with-image {
						padding-left: 0 !important;
						padding-right: 0 !important;
					}

					&.with-bg-image {
						@include media-breakpoint-down(sm) {
							padding-top: 6vh;
							padding-bottom: 6vh;
						}
					}
				}

				&.two {
					@include media-breakpoint-up(md) {
						padding-left: 125px !important;
					}

					@include media-breakpoint-up(xl) {
						padding-right: 45px !important;
					}

					@media (min-width: 1360px) {
						padding-right: 125px !important;
					}

					&.with-image,
					&.with-bg-image {
						padding-top: 0;
						padding-bottom: 0;
					}

					&.with-image {
						padding-left: 0 !important;
						padding-right: 0 !important;
					}

					&.with-bg-image {
						@include media-breakpoint-down(sm) {
							padding-top: 6vh;
							padding-bottom: 6vh;
						}
					}
				}

				.resource-image {
					height: 100%;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				&.with-bg-image {
					z-index: 1;

					.resource-image {
						z-index: -1;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							background: rgba($green, 0.6);
						}
					}
				}
			}
		}
	}
}

// simple-collection-section
// =========================================================================
.simple-collection-section {
	margin: 8vh 0;

	// collection
	.collection {
		&.slider {
			.owl-stage-outer {
				@include media-breakpoint-down(md) {
					margin-right: -15px !important;
				}

				.owl-stage {
					@include media-breakpoint-down(md) {
						padding-left: 0 !important;
					}
				}
			}
		}

		.card {
			position: relative;
			padding-top: 10vh;
			padding-bottom: 10vh;
			background: $blue;
			border: none;
			border-radius: 10px;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 30px;
				display: block;
				width: 93px;
				height: 12px;
				margin: 0 auto;
				background: url('/images/wave-single.svg');
			}

			.card-image {
				display: none;
			}

			.card-caption {
				color: $white;
				text-align: center;
			}

			.card-buttons {
				text-align: center;

				a {
					min-width: 160px;
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	margin: 6vh 0;

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 400px;
		max-height: 720px;

		.item {
			height: 75vh !important; // overide
			min-height: 400px;
			max-height: 720px;
			padding: unset; // overide

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.15);
			}

			.owl-container {
				position: relative;
				max-width: 750px;

				.owl-caption {
					text-shadow: none;

					.owl-title {
						@include font-size($h1-font-size);
					}
				}
			}
		}

		.owl-dots {
			.owl-dot {
				span {
					border-color: $white;
					background: $white;
					box-shadow: none;
				}

				&:hover,
				&.active {
					span {
						border-color: $red;
						background: $red;
					}
				}
			}
		}

		.owl-nav {
			display: none;
		}
	}

}

// outro-section
// =========================================================================
.outro-section {
	margin: 6vh 0;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;
			}
		}

		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;

	.gallery-horizontal .gallery-label {
		top: 15px;
		padding: 5px 15px;
		border-radius: 0 .25rem .25rem 0;
		color: $white;
		font-weight: 700;
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
