// custom-list
.custom-list {
	padding: 0 0 0 15px;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 25px;
		color: $gray;
		font-size: 18px;
		font-weight: 700;
		font-family: $font-family-secondary;

		&::before {
			content: '\f061';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $blue;
			font-size: 17px;
			font-weight: 900;
		}

		a {
			text-decoration: none !important;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 25px;
		color: $gray;
		font-family: $font-family-secondary;

		&::before {
			content: '\f058';
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $green;
			font-weight: 400;
		}

		a {
			text-decoration: none !important;
		}
	}
}
