// footer
.footer {
	margin-top: 8vh;
	color: $gray;
	text-align: center;

	a {
		color: $white;
		text-decoration: none;

		&:hover {
			color: inherit;
			text-decoration: underline;
		}
	}

	// footer-logo
	.footer-logo {

		.logo {
			width: 300px;
			margin: 0 auto;

			a {
				@extend .d-block;

				img {
					@extend .w-100;
				}
			}
		}
	}

	// footer-address
	.footer-address {
		position: relative;
		margin-top: 44px;
		padding: 5vh 0 10vh 0;
		background: $green;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 100%;
			width: 100%;
			height: 14px;
			margin-bottom: -1px;
			background: url('/images/footer-wave.png');
		}

		// footer-text
		.footer-text {
			line-height: 1.5;

			p {
				margin-bottom: 0;
			}

			strong {
				font-weight: 900;
			}
		}

		// footer-link
		.footer-link {
			.list {
				margin: 15px 0;
				justify-content: center;

				.list-item {
					margin: 5px 7.5px;

					.link {
						@extend .btn;
						@extend .btn-primary;
						font-size: 17px !important;
						font-weight: 900 !important;
						line-height: 24px !important;
						font-family: $font-family-primary !important;
					}
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			.list {
				justify-content: center;

				.list-item {
					margin: 0 12.5px;

					.link {
						display: block;
						font-size: 24px;

						&:hover {
							color: $gray;
						}
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		background: $green;
		padding: 30px 0;
		color: $white;

		ul {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				@include media-breakpoint-up(md) {
					+li {
						&::before {
							content: "|";
							margin: 0 10px;
						}
					}
				}

				a {
					color: $white;
					text-decoration: none;

					&:hover {
						color: inherit;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
