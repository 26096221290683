// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 35vh;
	min-height: 200px;
	max-height: 360px;

	@include media-breakpoint-up(sm) {
		height: 50vh;
		max-height: 540px;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 50vh;
	min-height: 250px;
	max-height: 360px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 175px);
		max-height: 1080px;
	}

	@include media-breakpoint-up(xl) {
		height: calc(100vh - 85px);
	}
}

// eyecatcher
.eyecatcher {
	.owl-carousel {
		.item {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.10);
			}

			.owl-container {
				position: relative;
				max-width: 750px;

				.owl-caption {
					text-shadow: none;

					.owl-title {
						@include font-size($h1-font-size);

						&::after {
							content: "";
							display: block;
							width: 93px;
							height: 12px;
							margin: 0.5rem auto 0 auto;
							background: url('/images/wave-single.svg');
						}

					}
				}
			}
		}
	}

	// owl
	.owl-dots,
	.owl-nav {
		display: none;
	}
}
